import { addMethod, string, mixed } from 'yup'

addMethod(string, 'password', function a(message) {
	return this.test('password', message, function b(value) {
		const { path, createError } = this
		if (!/^(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
			return createError({
				path,
				message: 'Пароль должен содержать хотя бы одну заглавную и одну строчную латинские буквы'
			})
		}
		if (!/(?=.*\d)/.test(value)) {
			return createError({
				path,
				message: 'Пароль должен содержать хотя бы одну цифру'
			})
		}

		return true
	})
})

addMethod(string, 'min', function a(min, message) {
	return this.test('min', message, function b(value) {
		const { path, createError } = this
		if (value.length >= min || !value) return true
		return createError({
			path,
			message: message ? message({ min }) : `Минимум ${min} символов`
		})
	})
})

addMethod(mixed, 'ext', function a(extArray, message) {
	return this.test('ext', message, function b(file) {
		const { path, createError } = this
		if (!file) return true
		if (!file.type) {
			return createError({
				path,
				message: `Неизвестный формат файла`
			})
		}

		const formattedExt = extArray.map((e) => e.toLowerCase())
		if (extArray.includes('mov')) formattedExt.push('quicktime')
		const fileExt = file.type.split('/')[1].toLowerCase()

		const hasFormat = formattedExt.includes(fileExt)
		if (hasFormat) return true

		const joinExt = extArray.map((e) => e.toUpperCase()).join(', ')
		const defaultMessage = `Допустимые форматы: ${joinExt}`
		return createError({
			path,
			message: message ? message({ defaultMessage, ext: joinExt }) : defaultMessage
		})
	})
})

addMethod(mixed, 'aspectRatio', function a(ar, message) {
	return this.test('aspectRatio', message, function b(file) {
		const { path, createError } = this
		const { width, height } = file
		if (width && height) {
			if (Math.max(width, height) / Math.min(width, height) < ar) return true
			const defaultMessage = 'Нельзя использовать файлы, у которых одна из сторон в несколько раз превышает другую'
			return createError({
				path,
				message: message ? message({ defaultMessage }) : defaultMessage
			})
		}
		return true
	})
})

addMethod(mixed, 'size', function a(size, message) {
	return this.test('size', message, function b(file) {
		const { path, createError } = this
		if (file.size < size) return true
		return createError({
			path,
			message: message ? message({ size }) : `Файл слишком большой. Максимальный размер — ${size / 1000000} Мб.`
		})
	})
})

addMethod(mixed, 'imageSize', function a(size, message) {
	return this.test('imageSize', message, function b(file) {
		const { path, createError } = this
		if (!file) return true
		const isImage = file.type.split('/')[0] === 'image'
		if (!isImage) return true
		if (file.size < size) return true
		const defaultMessage = `Фото слишком большое. Максимальный размер — ${size / 1000000} Мб.`
		return createError({
			path,
			message: message ? message({ defaultMessage, size }) : defaultMessage
		})
	})
})

addMethod(mixed, 'videoSize', function a(size, message) {
	return this.test('videoSize', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (file.size < size) return true
		return createError({
			path,
			message: message ? message({ size }) : `Видео слишком большое. Максимальный размер — ${size / 1000000} Мб.`
		})
	})
})

addMethod(string, 'noLinksOrEmail', function a(message) {
	return this.test('noLinksOrEmail', message, function b(value) {
		const { path, createError } = this
		const error = createError({
			path,
			message: 'В тексте есть ссылки или email'
		})
		if (!value) return true
		if (value.includes('@')) return error
		if (
			/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})|((?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})\.[a-z]{1,3}\b)+)/gim.test(
				value
			)
		) {
			return error
		}
		return true
	})
})

addMethod(mixed, 'audioSize', function a(size, message) {
	return this.test('audioSize', message, function b(file) {
		const { path, createError } = this
		const isAudio = file.type.split('/')[0] === 'audio'
		if (!isAudio) return true
		if (file.size < size) return true
		return createError({
			path,
			message: message ? message({ size }) : `Аудио слишком большое. Максимальный размер — ${size / 1000000} Мб.`
		})
	})
})

addMethod(mixed, 'minDimensions', function a([width, height], message) {
	return this.test('minDimensions', message, function b(file) {
		const { path, createError } = this
		if (file.type.includes('video') || (file.width >= width && file.height >= height)) return true
		const defaultMessage = `Фото слишком маленькое. Минимальный размер: ${width}px в ширину и ${height}px в высоту`
		return createError({
			path,
			message: message
				? message({
						defaultMessage,
						width,
						height
					})
				: defaultMessage
		})
	})
})

addMethod(mixed, 'minVideoDuration', function a(minDuration, message) {
	return this.test('minVideoDuration', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (file.duration > minDuration) return true
		const defaultMessage = `Видео слишком короткое. Минимальная длительность — ${minDuration} секунды`
		return createError({
			path,
			message: message ? message({ defaultMessage, minDuration }) : defaultMessage
		})
	})
})

addMethod(mixed, 'maxVideoDuration', function a(maxDuration, message) {
	return this.test('maxVideoDuration', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (maxDuration >= Math.trunc(file.duration)) return true
		const defaultMessage = `Видео слишком длинное. Максимальная длительность — ${maxDuration} секунд`
		return createError({
			path,
			message: message ? message({ defaultMessage, maxDuration }) : defaultMessage
		})
	})
})

addMethod(string, 'noOnlySpaces', function a(message) {
	return this.test('noOnlySpaces', message, function b(value) {
		const { path, createError } = this
		if (!value) return true
		if (/.*\S.*/gm.test(value)) {
			return true
		}
		return createError({
			path,
			message: message ?? 'Введите какой-нибудь символ'
		})
	})
})

// const requiredTexts = {
// 	password: 'Введите пароль',
// 	passwordConfirmation: 'Пароль необходимо повторить',
// 	gesture: 'Загрузите фото с жестом',
// 	photoWithPassport: 'Загрузите фото с паспортом',
// 	nickname: 'Введите имя в приложении',
// 	account_number: 'Введите номер карты',
// 	secret_number: 'Введите номер карты',
// 	price: 'Укажите цену от 99₽ до 10000₽',
// 	files: 'Добавьте хотя бы один файл',
// 	listName: 'Введите название',
// 	'main-text': 'Необходимо заполнить поле'
// }
//
// const betweenTexts = {
// 	price: (minPrice, maxPrice) => `Укажите цену от ${minPrice}₽ до ${maxPrice}₽`
// }
//
// const regexTexts = {
// 	account_number: 'Некорректный номер',
// 	secret_number: 'Некорректный номер'
// }
//
// const passwordTexts = {
// 	password: 'Пароль должен состоять из цифр и латинских букв'
// }
//
// const confirmedTexts = {
// 	passwordConfirmation: 'Пароли не совпадают'
// }
//
// const minTexts = {
// 	nickname: (length) => `Никнейм должен содержать минимум ${length} символа`,
// 	about_yourself: (length) => `Поле о себе должно содержать минимум ${length} символов`
// }
//
// const maxTexts = {
// 	nickname: (length) => `Никнейм должен содержать максимум ${length} символа`,
// 	about_yourself: (length) => `Поле о себе должно содержать максимум ${length} символа`,
// 	cardName: (length) => `Название карты должно содержать максимум ${length} символа`,
// 	listName: (length) => `Название списка должно содержать максимум ${length} символов`
// }
//
// const minValueTexts = {
// 	amount: (value) => `Минимальная сумма — ${digitsOfNumber(value)} ₽`
// }
//
// const maxValueTexts = {
// 	amount: () => `Сумма превышает разовый лимит`
// }
//
//
// extend('regex', {
// 	...regex,
// 	message: (field) => regexTexts[field]
// })
//
// extend('min_value', {
// 	...min_value,
// 	message: (field, { minValue }) => minValueTexts[field](minValue)
// })
//
// extend('max_value', {
// 	...max_value,
// 	message: (field, { maxValue }) => maxValueTexts[field](maxValue)
// })
//
// extend('balance', {
// 	...max_value,
// 	message: () => 'На балансе недостаточно средств'
// })
//
//
// extend('between', {
// 	...between,
// 	// eslint-disable-next-line no-shadow
// 	message: (field, { min, max }) => betweenTexts[field](min, max)
// })
