import { http } from '@/http/index'

async function getThreadsList() {
	return http.get(`/messenger/threads`).then((response) => response.data)
}

async function getMoreThreads(id) {
	return http.get(`/messenger/threads/page/${id}`).then((response) => response.data)
}

export { getThreadsList, getMoreThreads }
